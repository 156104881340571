// 法语
export default {
    accountDetailsPage:{
        accountDetails:'Détails du compte',
        startDate:'date de début',
        endDate:'date de fin',
        search:'recherche',
        alltypes:'tous types',
        withdrawalRecord:'Dossiers de retrait',
        rechargeRecord:'Recharger l\'enregistrement',
        withDrawal:'Retirer de l\'argent',
        UAddress:'U adresse',
        rebateForOrderGrabbing:'Rabais de commande',
        orderGrab:'Prenez une commande',
        recharge:'recharger',
        tryToLoad:'Chargement difficile',
        noMore:'pas plus',
        cancel:'Annuler',
        ok:'Bien sûr',
        pleaseSelectATime:'Veuillez sélectionner une heure'
    },
    teamPage:{
        teamReport:'Rapports d\'équipe',
        startDate:'date de début',
        endDate:'date de fin',
        search:'recherche',
        teamBalance:'Equilibre de l\'équipe($)',
        teamFlow:'Flux d\'équipe($)',
        totalTeamRecharge:'Recharge totale de l\'équipe($)',
        theTotalWithdrawalOfTheTeam:'Retrait total de l\'équipe($)',
        teamOrderCommission:'Commission de commande d\'équipe($)',
        myCommission:'ma commission($)',
        directPushNumber:'Nombre de références directes',
        teamSize:'Taille de l\'équipe',
        todaysNewNumberOfPeople: 'Nouvelles personnes ajoutées aujourd\'hui',
        todaysActiveNumber:'Nombre de personnes actives aujourd\'hui',
        level1:'Niveau 1',
        level2:'Niveau 2',
        level3:'Niveau trois',
        tryToLoad:'Chargement difficile',
        noMore:'pas plus',
        mobilePhone:'Numéro de téléphone',
        Number:'Numéro de promotion',
        withdrawals:'Retirer de l\'argent',
        recharge:'recharger',
        time:'Heure d\'inscription'
    },
    culturePage:{
        title:'Qualifications de l\'entreprise',
        text:'AppLovin adhère au concept « centré sur le client » et à la conviction de « prix bas tous les jours, produits authentiques », vendant des dizaines de millions de produits tels que des livres, des ordinateurs, des appareils électroménagers numériques, des grands magasins pour mères et nourrissons, des vêtements et des sacs. , etc. AppLovin China fournit des services professionnels : des produits authentiques sous licence à bas prix chaque jour et des factures imprimées à la machine sont garanties dans tout le pays. Paiement à la livraison, retours et échanges sous 30 jours. AppLovin offre aux consommateurs du monde entier une expérience d\'achat en ligne pratique et rapide'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Numéro de téléphone',
        mobileNumberPlaceholder:'Veuillez entrer le numéro de téléphone portable',
        mobileNumberMessage:'Veuillez saisir un numéro de téléphone mobile standardisé',
        password:'mot de passe',
        passwordPlaceholder:'Veuillez entrer le mot de passe',
        passwordMessage:'Le mot de passe que vous avez entré est incorrect. Veuillez réessayer.',
        forgotPassword:'oublier le mot de passe',
        goToRegister:'Allez vous inscrire',
        rememberPassword:'Mémoriser le mot de passe',
        login:'Se connecter',
        noAccountYet:'Pas encore de compte?',
        loginSuccessful:'Connexion réussie！',
        loginFailed:'La connexion a échoué！'
      },
    //   注册页
      registerPage:{
        register:' registre',
        mobileNumber:'Numéro de téléphone',
        mobileNumberPlaceholder :'Veuillez entrer le numéro de téléphone portable',
        mobileNumberMessage:'Veuillez saisir un numéro de téléphone mobile standardisé',
        loginPassword:'Mot de passe de connexion',
        loginPasswordPlaceholder:'Veuillez entrer votre mot de passe de connexion',
        loginPasswordMessage:'Le mot de passe de connexion que vous avez défini ne peut pas être vide',
        invitationCode:'Code d\'invitation',
        invitationCodePlaceholder:'Veuillez entrer le code d\'invitation',
        invitationCodeMessage:'Le code d\'invitation que vous avez entré est incorrect',
        fundingPassword:'Mot de passe du fonds',
        fundingPasswordPlaceholder:'Veuillez saisir le mot de passe du fonds',
        fundingPasswordMessage:'Le mot de passe du fonds que vous avez défini ne peut pas être vide',
        iKnowAndAgree:'J\'ai compris et accepté',
        accountOpeningAgreement:'"Convention d\'ouverture de compte"',
        theTreaties:'traités',
        signUpNow:'Inscrivez-vous maintenant',
        pleaseAgree:'Veuillez d\'abord accepter le contrat d\'inscription',
        existingAccount:'Vous avez déjà un compte',
        loginNow:'Connectez-vous maintenant',
        registrationFailed:'L\'inscription a échoué',
        registrationSuccessful:'Inscription réussie',
        iKnow:'Je sais',
        accountOpen:'Convention d\'ouverture de compte'
      },
      // my页面
      myPage:{
        logout:'Se déconnecter',
        areYouSureYouWantToLogOut:'Êtes-vous sûr de vous déconnecter ？',
        confirnText:'Bien sûr',
        cancleText:'Annuler',
        amazonReputation:'Points de réputation:',
        accountBalance:'Solde du compte',
        recharge:'recharger',
        withdrawal:'Retirer de l\'argent',
        deliveryAddress:'Adresse de livraison',
        iWantToPromote:'je veux promouvoir',
        secondPurchaseRecord:'Deuxième dossier d\'achat',
        accountDetails:'Détails du compte',
        teamReport:'Rapports d\'équipe',
        companyQualification:'Certificat de constitution'
      },
      // 充值页面
      rechargePage:{
        recharge:'recharger',
        currentBalance:'Solde courant($)',
        rechargeApplication:'Demande de recharge en cours($)',
        accumulatedRecharged:'Totalement rechargé($)',
        rechargeAmount:'Montant de la recharge',
        pleaseEnterTheRechargeAmount:'Veuillez saisir le montant de la recharge',
        rechargeNow:'Rechargez maintenant'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Impossible d\'obtenir la liste des commandes'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'Retirer de l\'argent',
       currentBalance:'Montant actuel($)',
       withdrawalApplicationInProgress:'Demande de retrait en cours($)',
       cumulativelyWithdrawn:'Retraits d\'espèces cumulés($)',
       withdrawalAmount:'Montant du retrait',
       pleaseEnterTheWithdrawalAmount:'Veuillez saisir le montant du retrait',
       withdrawAll:'Tout retirer',
       uAddress:'U adresse',
       pleaseEnterUAddress:'Veuillez entrer U adresse',
       transactionPassword:'Mot de passe de transaction',
       pleaseEnterTheTransactionPassword:'Veuillez saisir le mot de passe de la transaction',
       withdrawNow:'Retirer immédiatement',
       allFieldsCannotBeEmpty:'Tous les champs ne peuvent pas être vides',
       able:'Montant des liquidités disponibles',
	   networkType:'Type de réseau',
	   minPrice: 'Montant minimum de retrait 20$'
   },
      // 主页页面
    indexPage:{
      indexList:'Échec de l\'obtention des données de la page d\'accueil',
      amazonLetMiaogouOpenYourWayToWealth:'AppLovin Laissez Miagou ouvrir votre chemin vers la richesse',
      myAssets:'mes atouts($)',
      recharge:'recharger',
      withdrawal:'Retirer de l\'argent',
      yesterdaysIncome:'Les gains d\'hier($)',
      cumulativeIncome:'Revenu cumulé($)',
      todaysIncome:'Les gains d\'aujourd\'hui($)',
      freezeFunds:'geler les fonds($)',
      regularFinancialManagement:'Gestion financière régulière',
      quickRecharge:'Recharge rapide',
      quickWithdrawal:'Retrait d\'argent rapide',
      inviteFriends:'Inviter des amis',
      taskHall:'salle des missions',
      unlocked:'Débloqué',
	  service:'service client',
	  leaderboard:'Dossiers de retrait'
    },
    addressPage:{
      address:'adresse',
      realName:'vrai nom',
      pleaseEnterYourRealName:'Veuillez entrer votre vrai nom',
      mobileNumber:'Numéro de téléphone',
      pleaseEnterThePhoneNumber:'Veuillez entrer le numéro de téléphone portable',
      region:'zone',
      pleaseEnterTheRegion:'Veuillez entrer la région',
      detailedAddress:'Adresse détaillée',
      pleaseEnterDetailedAddress:'Veuillez entrer l\'adresse détaillée',
      submit:'soumettre'
    },
    // order页面
    orderPage:{
      grabAnOrder:'Prise de commandes non-stop',
      hiShop:'Bonjour',
      winTheFutureWithYou:'Gagnez l\'avenir avec vous',
      orderText:'Fondée en 1994, AppLovin travaille avec plus d\'un million d\'entreprises de commerce électronique et traite entre 150 000 et 30 millions de commandes par jour. Étant donné que la gestion des fonds est confiée à la société de gestion d\'actifs, les demandes de remboursement des utilisateurs et les fuites de fonds des sociétés de commerce électronique peuvent être évitées, et le client cloud intelligent et l\'adresse IP de l\'utilisateur sont utilisés selon des règles définies. Le concours de passation automatique de commandes empêchera les commerçants d\'être bloqués, déclassés ou bloqués en raison d\'erreurs commises par les utilisateurs qui récupèrent les commandes. En tant qu\'intermédiaire, nous continuerons à travailler dur pour améliorer la confiance et l\'efficacité des commerçants et des utilisateurs lors de la concurrence pour les commandes !',
      bronzeMember:'Membre Bronze',
      commission:'commission',
      Matching:'La commande est en cours de correspondance pour vous. Veuillez patienter.',
      dispatch:'ordre d\'expédition',
      grabAnOrder:'Prenez une commande',
      balance:'équilibre',
      recharge:'recharger',
      withdrawal:'Retirer de l\'argent',
      matchingRules:'Règles de correspondance',
      commissionEarnedToday:'Commission gagnée aujourd\'hui',
      Today:'Nombre de commandes prises aujourd\'hui',
      oddNumber:'Nombres impairs restants du jour',
      yesterdays:'La commission d\'hier',
      User:'utilisateur',
      getCommission:'obtenir une commission'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'je veux promouvoir',
      inviteNewUsers:'Inviter de nouveaux utilisateurs',
      winTheFutureWithYou:'Gagnez l\'avenir avec vous',
      screenshot:'Prenez une capture d\'écran et enregistrez le code QR',
      friend:'Identification et enregistrement des amis',
      complete:'Terminez l\'inscription et récupérez les commandes',
      distribute:'Enveloppes rouges cash distribuées sous 24 heures',
      invitationCode:'Code d\'invitation:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Deuxième dossier d\'achat',
      this:'Ces données sont fournies par les principaux responsables des coopératives',
      Remaining:'actifs disponibles restants($)',
      allOrders:'Toutes les commandes',
      toBeProcessed:'En attente',
      completed:'Complété',
      freezing:'Gel',
      totalAmountOfTheOrder:'Solde total des commandes',
      commission:'commission',
      Estimated:'Remise de commission estimée',
      submitOrder:'Soumettre la commande',
      secondpurchasetime:'Heure du deuxième achat:',
      secondPurchaseNumber:'Deuxième numéro d\'achat:',
      NotSubmitted:'Pas encore soumis',
      submitNow:'Soumettre maintenant'
    },
	
	vipPage:{
		'agent_mode': 'Mode Agent',
		'current_level': 'Niveau actuel',
		'vip1_text1': 'Recevez un ensemble de 40 tâches de données d\'applications',
		'vip1_text2': 'Bénéfice de 0,50% par application',
		'vip1_text3': 'Activer avec 100.00 USDT',
		
		'vip2_text1': 'Recevez un ensemble de 50 tâches de données d\'applications',
		'vip2_text2': 'Bénéfice de 0,60% par application',
		'vip2_text3': 'Activer avec 2,000.00 USDT',
		
		'vip3_text1': 'Recevez un ensemble de 55 tâches de données d\'applications',
		'vip3_text2': 'Bénéfice de 0,80% par application',
		'vip3_text3': 'Activer avec 5,000.00 USDT',
		
		'vip4_text1': 'Recevez un ensemble de 60 tâches de données d\'applications',
		'vip4_text2': 'Bénéfice de 1,00% par application',
		'vip4_text3': 'Activer avec 10,000.00 USDT',
	}
}