export default {
  loginPage:{
    mobileNumber:'手機號',
    password:'密碼',
    forgotPassword:'忘記密碼',
    goToRegister:'去註冊',
    rememberPassword:'記住密碼',
    login:'登錄',
    noAccountYet:'還沒有賬號?',
    mobileNumberPlaceholder:'請輸入手機號碼',
    mobileNumberMessage:'請輸入手機號碼',
    passwordPlaceholder:'請輸入密碼',
    passwordMessage:'請輸入密碼',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
   //   注册页
   registerPage:{
    register:'  註冊 ',
    mobileNumber:'手機號',
    mobileNumberPlaceholder :'請輸入手機號',
    mobileNumberMessage:'請輸入手機號',
    loginPassword:'登錄密碼',
    loginPasswordPlaceholder:'請輸入登錄密碼',
    loginPasswordMessage:'請輸入登錄密碼',
    invitationCode:'邀請碼',
    invitationCodePlaceholder:'請輸入邀請碼',
    invitationCodeMessage:'請輸入邀請碼',
    fundingPassword:'資金密碼',
    fundingPasswordPlaceholder:'請輸入資金密碼',
    fundingPasswordMessage:'請輸入資金密碼',
    iKnowAndAgree:'我已知曉並同意',
    accountOpeningAgreement:'"開戶協議"',
    theTreaties:'各項條約',
    signUpNow:'立即註冊',
    pleaseAgree:'請先同意註冊協定',
    existingAccount:'已有賬號',
    loginNow:'"馬上登錄"',
    registrationFailed:'註冊失敗',
    registrationSuccessful:'註冊成功',
    iKnow:'我知道',
    accountOpen:'開戶協議'
  },
  // 主页页面
  indexPage:{
    indexList:'獲取首頁數據失敗',
    amazonLetMiaogouOpenYourWayToWealth:'AppLovin讓秒購開放您的財富之路',
    myAssets:'我的資產($)',
    recharge:'充值',
    withdrawal:'提現',
    yesterdaysIncome:'昨日收益($)',
    cumulativeIncome:'累計收益($)',
    todaysIncome:'今日收益($)',
    freezeFunds:'凍結資金($)',
    regularFinancialManagement:'定期理財',
    quickRecharge:'快速充值',
    quickWithdrawal:'快速提現',
    inviteFriends:'邀請好友',
    taskHall:'任務大廳',
    unlocked:'已解鎖',
	service:'客服',
	leaderboard:'提現記錄'
  },
  // 抢单列表页面
  grabListPage:{
    failedToGetTheOrderGrabList:'搶單清單獲取失敗'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'秒購記錄',
    this:'本數據由各大合作官方提供',
    Remaining:'剩餘可用資產($)',
    allOrders:'全部單',
    toBeProcessed:'待處理',
    completed:'已完成',
    freezing:'凍結中',
    totalAmountOfTheOrder:'訂單總金額',
    commission:'佣金',
    Estimated:'預計返佣',
    submitOrder:'提交訂單',
    secondpurchasetime:'秒購時間:',
    secondPurchaseNumber:'秒購編號:',
    NotSubmitted:'暫不提交',
    submitNow:'立即提交'
  },
  // order页面
  orderPage:{
    grabAnOrder:'搶單不停',
    hiShop:'嗨購',
    winTheFutureWithYou:'與您共贏未來',
    orderText:'AppLovin Marketplace 成立於 1994 年，與超過 100 萬家電子商務公司合作，每天處理 15 萬至 3000 萬個訂單。由於資金管理委託給資產管理公司，因此可以避免用戶退款請求和電子商務公司的資金外逃，智能雲客戶端和用戶IP按照設定的規則使用。自動下單競賽將防止商家因搶單用戶的失誤而被封號、降級、封號。作為中介，我們將繼續努力提高商家和用戶在爭奪訂單時的信任度和效率！',
    bronzeMember:'青銅會員',
    commission:'佣金',
    Matching:'正在為您匹配訂單請稍後',
    dispatch:'派單',
    grabAnOrder:'搶單',
    balance:'餘額',
    recharge:'充值',
    withdrawal:'提現',
    matchingRules:'匹配規則',
    commissionEarnedToday:'今日已賺佣金',
    Today:'今日已搶單數',
    oddNumber:'今日剩餘單數',
    yesterdays:'昨日佣金',
    User:'用戶',
    getCommission:'獲得佣金'
  },
  // my页面
  myPage:{
    logout:'退出登錄',
    areYouSureYouWantToLogOut:'確定退出登錄?',
    confirnText:'確定',
    cancleText:'取消',
    amazonReputation:'AppLovin 信譽:',
    accountBalance:'賬戶餘額',
    recharge:'充值',
    withdrawal:'提現',
    deliveryAddress:'收貨地址',
    iWantToPromote:'我要推廣',
    secondPurchaseRecord:'秒購記錄',
    accountDetails:'賬戶明細',
    teamReport:'團隊報表',
    companyQualification:'公司證書'
  },
  teamPage:{
    teamReport:' 團隊報表 ',
    startDate:'開始日期',
    endDate:'結束日期',
    search:'搜索',
    teamBalance:'團隊餘額($)',
    teamFlow:'團隊流水($)',
    totalTeamRecharge:'團隊總充值($)',
    theTotalWithdrawalOfTheTeam:'團隊總提現($)',
    teamOrderCommission:'團隊訂單佣金($)',
    myCommission:'我的佣金($)',
    directPushNumber:'直推人數',
    teamSize:'團隊人數',
    todaysNewNumberOfPeople: '今日新增人數',
    todaysActiveNumber:'今日活躍人數',
    level1:'一級',
    level2:'二級',
    level3:'三級',
    tryToLoad:'努力載入中',
    noMore:'沒有更多',
    mobilePhone:'手機號',
    Number:'推廣人數',
    withdrawals:'提現',
    recharge:'充值',
    time:'註冊時間'
},
accountDetailsPage:{
  accountDetails:' 賬戶明細 ',
  startDate:'開始日期',
  endDate:'結束日期',
  search:'搜索',
  alltypes:'所有類型',
  withdrawalRecord:'提現記錄',
  rechargeRecord:'充值記錄',
  withDrawal:'提現',
  UAddress:'U位址',
  rebateForOrderGrabbing:'搶單返傭',
  orderGrab:'搶單',
  recharge:'充值',
  tryToLoad:'努力載入中',
  noMore:'沒有更多',
  cancel:'取消',
  ok:'確定',
  pleaseSelectATime:'請選擇時間'
},
culturePage:{
  title:' 公司資質 ',
  text:'亞馬遜堅持“以客戶為中心”的理念，秉承“天天低價，正品行貨”信念，銷售圖書、電腦、數碼家電、母嬰百貨、服飾箱包等上千萬種產品。亞馬遜中國提供專業服務：正品行貨天天低價，機打發票全國聯保。貨到付款，30天內可退換貨。亞馬遜為全球消費者提供便利、快捷的網購體驗'
},
addressPage:{
  address:'地址',
  realName:'真實姓名',
  pleaseEnterYourRealName:'請輸入真實姓名',
  mobileNumber:'手機號',
  pleaseEnterThePhoneNumber:'請輸入手機號',
  region:'地區',
  pleaseEnterTheRegion:'請輸入地區',
  detailedAddress:'詳細地址',
  pleaseEnterDetailedAddress:'請輸入詳細地址',
  submit:'提交'
},
// Popularize页面
popularizePage:{
  iWantToPromote:' 我要推廣 ',
  inviteNewUsers:'邀請新用戶',
  winTheFutureWithYou:'與您共贏未來',
  screenshot:'截圖保存二維碼',
  friend:'好友識別並註冊',
  complete:'完成註冊搶單',
  distribute:'24小時內發放現金紅包',
  invitationCode:'邀請碼:'
},
// 提现页面
withDrawalPage:{
  withDrawal:' 提現 ',
  currentBalance:'當前餘額($)',
  withdrawalApplicationInProgress:'提現申請中($)',
  cumulativelyWithdrawn:'累計已提現($)',
  withdrawalAmount:'提現金額',
  pleaseEnterTheWithdrawalAmount:'請輸入提現金額',
  withdrawAll:'全部提現',
  uAddress:'U地址',
  pleaseEnterUAddress:'請輸入U地址',
  transactionPassword:'交易密碼',
  pleaseEnterTheTransactionPassword:'請輸入交易密碼',
  withdrawNow:'立即提現',
  allFieldsCannotBeEmpty:'所有填寫項不能為空',
  able:'可提現金額',
  networkType:'網路類型',
	   minPrice: '最低提現金額$20'
},
// 充值页面
rechargePage:{
  recharge:'充值',
  currentBalance:'當前餘額 ($)',
  rechargeApplication:'充值申請中($)',
  accumulatedRecharged:'累計已充值($)',
  rechargeAmount:'充值金額',
  pleaseEnterTheRechargeAmount:'請輸入充值數量',
  rechargeNow:'立即充值'
},
	vipPage:{
		'agent_mode': '代理模式',
		'current_level': '目前等級',
		'vip1_text1': '接收一組 40 個應用資料任務',
		'vip1_text2': '每份申請可獲 0.50% 利潤',
		'vip1_text3': '使用 100.00 USDT 激活',
		
		'vip2_text1': '接收一組 50 個應用資料任務',
		'vip2_text2': '每份申請可獲 0.60% 利潤',
		'vip2_text3': '使用 2,000.00 USDT 激活',
		
		'vip3_text1': '接收一組 55 個應用資料任務',
		'vip3_text2': '每份申請可獲 0.80% 利潤',
		'vip3_text3': '使用 5,000.00 USDT 激活',
		
		'vip4_text1': '接收一組 60 個應用資料任務',
		'vip4_text2': '每份申請可獲 1.00% 利潤',
		'vip4_text3': '使用 10,000.00 USDT 激活',
	}
}